// ===================================
// TABS • Update Title and Favicon
// ===================================

var originalTitle = document.title;
var darkModeFavicon =
  "https://cdn.prod.website-files.com/669d97109d5970e08c6816e1/671d84cbd7c1176b65f3a604_favicon-light.png";

function isDarkMode() {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
}

var originalFavicon = isDarkMode()
  ? darkModeFavicon
  : document.querySelector("link[rel~='icon']").href;

window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", function (e) {
    if (!document.hidden) {
      originalFavicon = e.matches
        ? darkModeFavicon
        : document.querySelector("link[rel~='icon']").href;
      changeFavicon(originalFavicon);
    }
  });

var scrollingText = "Il y a quelqu'un ? Il y a quelqu'un ? ";
var scrollingSpeed = 1000;
var scrollInterval;
var faviconInterval;
var step = 4;

var favicons = [
  "https://cdn.prod.website-files.com/669d97109d5970e08c6816e1/671d81f7194e2fbb7e4c446a_ico-certif-1.png",
  "https://cdn.prod.website-files.com/669d97109d5970e08c6816e1/671d81f70752c1fed91d1e6b_ico-certif-2.png",
  "https://cdn.prod.website-files.com/669d97109d5970e08c6816e1/671d81f76f4c86e304440238_ico-certif-3.png",
];

var currentFaviconIndex = 0;

document.addEventListener("visibilitychange", function () {
  if (document.hidden) {
    document.title = scrollingText;
    startScrollingTitle();
    startFaviconRotation();
  } else {
    stopScrollingTitle();
    stopFaviconRotation();
    document.title = originalTitle;
    changeFavicon(originalFavicon);
  }
});

function changeFavicon(src) {
  var link =
    document.querySelector("link[rel~='icon']") ||
    document.createElement("link");
  link.rel = "icon";
  link.href = src;
  if (!document.querySelector("link[rel~='icon']")) {
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}

function startFaviconRotation() {
  faviconInterval = setInterval(function () {
    changeFavicon(favicons[currentFaviconIndex]);
    currentFaviconIndex = (currentFaviconIndex + 1) % favicons.length;
  }, 1000);
}

function stopFaviconRotation() {
  clearInterval(faviconInterval);
}

function startScrollingTitle() {
  var currentIndex = 0;
  scrollInterval = setInterval(function () {
    document.title =
      scrollingText.substring(currentIndex) +
      scrollingText.substring(0, currentIndex);
    currentIndex = (currentIndex + step) % scrollingText.length;
  }, scrollingSpeed);
}

function stopScrollingTitle() {
  clearInterval(scrollInterval);
  document.title = originalTitle;
}

if (isDarkMode()) {
  changeFavicon(darkModeFavicon);
}

// ===================================
// CONSOLE • MESSAGE
// ===================================

function displayStyledMessage() {
  let messages = [
    "🧡 Tu as trouvé un bug ? 🧡 https://ntiz.io/bug",
    "💰 Tu veux gagner 10% de mes missions ? 💰 https://ntiz.io/affiliation",
    "🤝 Tu veux qu'on bosse ensemble ? 🤝 https://ntiz.io/partenariat",
  ];

  let styles = [
    "font-size: 12px",
    "color: #fffce1",
    'font-family: "Courier New", monospace',
    "background: #0e100f",
    "display: inline-block",
    "padding: 0.5rem 1rem",
    "border: 2px dashed #ff4200",
    "border-radius: 4px",
    "line-height: 1",
    "letter-spacing: -1px",
    "margin-bottom: 8px",
  ].join(";");

  messages.forEach((message) => {
    console.log(`%c${message}`, styles);
  });
}

document.addEventListener("DOMContentLoaded", displayStyledMessage);

// ===================================
// FOOTER
// ===================================

// =========== SOCIAL ===========

window.addEventListener("DOMContentLoaded", () => {
  const card = document.querySelector(".follower_outer-wrap");

  if (!card) {
    console.warn("L'élément .follower_outer-wrap n'a pas été trouvé");
    return;
  }

  const logos = card.querySelectorAll(".follower_item");
  const tooltip = card.querySelector(".follower_follower");

  if (!logos.length || !tooltip) {
    console.warn(
      "Les éléments .follower_item ou .follower_follower n'ont pas été trouvés"
    );
    return;
  }

  logos.forEach((logo, index) => {
    logo.addEventListener("mouseenter", () => {
      const name = logo.getAttribute("follower-name");
      if (!name) {
        console.warn("L'attribut follower-name est manquant");
        return;
      }
      tooltip.textContent = name;

      let newPosition =
        logo.getBoundingClientRect().left -
        logo.parentNode.getBoundingClientRect().left;

      if (index === 0) {
        tooltip.style.transform = "translateX(0%)";
      } else if (index === logos.length - 1) {
        newPosition += logo.offsetWidth;
        tooltip.style.transform = "translateX(-100%)";
      } else {
        newPosition += logo.offsetWidth / 2;
        tooltip.style.transform = "translateX(-50%)";
      }

      tooltip.style.left = newPosition + "px";

      const color = logo.getAttribute("follower-color");
      if (color) {
        tooltip.style.backgroundColor = `color-mix(in srgb, ${color} 20%, white)`;
        tooltip.style.color = color;
      }
    });
  });
});

// =========== WEATHER ===========

function updateWeather() {
  const latitude = 49.0932008;
  const longitude = 1.4857205;
  const api_url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true`;

  fetch(api_url)
    .then((response) => response.json())
    .then((data) => {
      const weatherElement = document.getElementById("weather");
      const weathercode = data.current_weather.weathercode;
      const isDay = data.current_weather.is_day;

      document.getElementById("weather-icon-rain").style.display = "none";
      document.getElementById("weather-icon-moon").style.display = "none";
      document.getElementById("weather-icon-sun").style.display = "none";

      if (
        (weathercode >= 61 && weathercode <= 65) ||
        (weathercode >= 80 && weathercode <= 82)
      ) {
        document.getElementById("weather-icon-rain").style.display = "inline";
      } else if (isDay === 0) {
        document.getElementById("weather-icon-moon").style.display = "inline";
      } else {
        document.getElementById("weather-icon-sun").style.display = "inline";
      }

      weatherElement.innerHTML = `Température actuelle : ${data.current_weather.temperature.toFixed(
        2
      )}°C&nbsp;`;
    })
    .catch((error) => console.error("Error fetching weather data:", error));
}

updateWeather();

// =========== YEAR =========================

document.addEventListener("DOMContentLoaded", function () {
  const e = document.querySelector(".year"),
    t = new Date().getFullYear();
  e.textContent = t;
});

// =========== DISPLAY POPUP LINK ===========

document.addEventListener("DOMContentLoaded", function () {
  const anchor = window.location.hash.substr(1);

  if (anchor) {
    const element = document.querySelector(`[data-anchor="${anchor}"]`);

    if (element) {
      document.querySelectorAll("[data-anchor]").forEach((el) => {
        el.style.display = "none";
      });

      const displayModes = element.getAttribute("data-display-modes") || "flex";
      const modes = displayModes.split(",").map((mode) => mode.trim());

      let appliedMode = "flex";
      modes.forEach((mode) => {
        if (["block", "flex", "grid"].includes(mode)) {
          appliedMode = mode;
        }
      });

      element.style.display = appliedMode;
    }
  }
});
